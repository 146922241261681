<ng-scrollbar
  externalViewport
  appearance="compact"
  visibility="hover"
  [ngClass]="{ 'w-full': true }"
>
  <div scrollViewport [ngClass]="{ 'w-full': true }">
    <div class="flex flex-col justify-between h-[90vh] gap-10 px-2">
      <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        @for (nav of navList(); track $index) {

        <li
          nz-menu-item
          nzMatchRouter
          nz-tooltip
          nzTooltipPlacement="right"
          [nzTooltipTitle]="isCollapsed ? nav.name : ''"
          [ngClass]="{
            '!h-[unset] !px-0': true,
            '!bg-[#F6FFFB] !text-[#226449] !font-semibold rounded':
              currentPath === nav.link
          }"
        >
          <a
            [routerLink]="nav.link"
            class="flex items-center justify-center gap-3 px-4 py-3"
          >
            <svg-icon [src]="nav.icon"></svg-icon>
            <span
              [ngClass]="{
                'text-[#717272] text-lg flex-1': true,
                '!bg-[#F6FFFB] !text-[#226449] !font-semibold':
                  currentPath === nav.link
              }"
              >{{ nav.name }}</span
            >
          </a>
        </li>
        }
      </ul>

      <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        @for (nav of navList2(); track $index) {
        <li
          nz-menu-item
          [nzDanger]="nav.type === 'Danger'"
          [nzMatchRouterExact]="nav.exact"
          nzMatchRouter
          nz-tooltip
          nzTooltipPlacement="right"
          [nzTooltipTitle]="isCollapsed ? nav.name : ''"
          (click)="logoutHandler(nav.name, nav.link)"
          role="button"
        >
          <a class="flex gap-3 items-center min-w-[40px] min-h-[40px]">
            <svg-icon [src]="nav.icon"></svg-icon>
            <span>{{ nav.name }}</span>
          </a>
        </li>
        }
      </ul>
    </div>
  </div>
</ng-scrollbar>
