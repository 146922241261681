import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsReducerInitialState } from '../reducers/settings.reducer';

const getSettingsReducerState =
  createFeatureSelector<SettingsReducerInitialState>('settings');

export const getSettingsStore = createSelector(
  getSettingsReducerState,
  (state) => state
);
