import { Component, Input, inject, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from 'angular-svg-icon';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { GeneralButtonDirective } from '../../directives/buttons/general-button.directive';
import { LocalService } from '../../../core/services/local.service';
import { OutsideClickDirective } from '../../directives/outside-click.directive';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import { setStateAction } from '../../../core/store/actions/settings.actions';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { Subscription } from 'rxjs';
import { getSettingsStore } from '../../../core/store/selectors/settings.selectors';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrl: './user-header.component.css',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SvgIconComponent,
    MatIconModule,
    GeneralButtonDirective,
    OutsideClickDirective,
    NzAvatarModule,
    NzDropDownModule,
    NzButtonModule,
  ],
  host: { class: 'flex flex-1' },
})
export class UserHeaderComponent {
  store = inject(Store<AppState>);
  localService = inject(LocalService);
  authService = inject(AuthService);
  matIconregistry = inject(MatIconRegistry);
  domSanitizer = inject(DomSanitizer);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  @Input() hideRoutes = false;
  settingsStoreSub: Subscription;

  navLists = [
    {
      name: 'Home',
      link: '/dashboard',
      type: 'All',
    },
    {
      name: 'Tax Payment',
      link: '/tax-payment',
      type: 'All',
    },
    {
      name: 'Invoices',
      link: '/invoices',
      type: 'All',
    },
    {
      name: 'Payment History',
      link: '/payment-history',
      type: 'All',
    },
    {
      name: 'E-Services',
      link: '/e-services',
      type: 'All',
    },
    {
      name: 'PAYE Manager',
      link: '/paye-manager',
      type: 'All',
    },
    // {
    //   name: 'CREATE',
    //   link: '/create',
    //   type: 'Administrator',
    // },
  ];
  showPopover = false;
  sideMenuCollapsedState = signal(false);

  get userData() {
    return this.localService.getData('pay_kd_user');
  }

  logoutHandler() {
    this.authService.clearUserData();
    this.router.navigateByUrl('/', { replaceUrl: true });
  }

  get abbr() {
    const firstName = (this.userData?.firstName as string) || '';
    const lastName = (this.userData?.lastName as string) || '';
    return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`;
  }
  get fullName() {
    const firstName = (this.userData?.firstName as string) || '';
    const lastName = (this.userData?.lastName as string) || '';
    return `${firstName} ${lastName}`;
  }

  toggleSideMenuCollapsedState() {
    this.store.dispatch(
      setStateAction({ sideMenuCollapsedState: !this.sideMenuCollapsedState() })
    );
  }

  constructor() {
    this.matIconregistry.addSvgIcon(
      'logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svgs/logo_svg.svg'
      )
    );
  }

  clickStatusHandler(status: any) {
    if (!status) {
      this.showPopover = false;
    }
  }

  popoverHandler() {
    this.showPopover = !this.showPopover;
  }

  ngOnInit(): void {
    this.settingsStoreSub = this.store
      .pipe(select(getSettingsStore))
      .subscribe((settingsStore) => {
        this.sideMenuCollapsedState.set(settingsStore.sideMenuCollapsedState);
      });
  }

  ngOnDestroy(): void {
    if (this.settingsStoreSub) this.settingsStoreSub.unsubscribe();
  }
}
