<header
  [ngClass]="{
    'body-font py-2 flex flex-1 items-center': true
  }"
>
  <button
    nz-button
    nzShape="round"
    nzType="link"
    (click)="toggleSideMenuCollapsedState()"
  >
    <svg-icon
      src="assets/svgs/menu.svg"
      [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
    ></svg-icon>
  </button>

  <div [ngClass]="{ 'flex items-center justify-end gap-6 flex-1': true }">
    <span>
      <a
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="menu"
        class="flex items-center gap-2"
      >
        <nz-avatar
          [nzSize]="'large'"
          [nzGap]="4"
          [nzText]="abbr"
          [ngClass]="{ 'bg-tw-primary': true }"
          [nzSrc]="userData?.avatar"
        ></nz-avatar>

        <span class="flex flex-col gap-0">
          <p class="text-xs font-semibold text-left capitalize">
            {{ fullName || "User" }}
          </p>
        </span>

        <svg-icon
          src="assets/svgs/chevron-up.svg"
          [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
        ></svg-icon>
      </a>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu class="w-[150px]">
          <li nz-menu-item (click)="router.navigate(['/dashboard/profile'])">
            Profile
          </li>

          <li nz-menu-item (click)="router.navigate(['/dashboard/logs'])">
            Service Logs
          </li>

          <li nz-menu-item nzDanger (click)="logoutHandler()" role="button">
            Log Out
          </li>
        </ul>
      </nz-dropdown-menu>
    </span>
  </div>
</header>

<!-- *ngIf="!!authService.userData&&!hideRoutes -->
